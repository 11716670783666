import React, { useState, useRef, useEffect } from "react"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import useScript from "../service/useScript"
import SimpleReactValidator from "simple-react-validator"
import toast from 'react-hot-toast'
import api from "../api/refer-patient"
import { useStaticQuery, graphql } from 'gatsby'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Thankyou from '../components/addon/thankYou'
import APIButton from "../components/addon/button/apiButton";
import { acceptOnlyNumbers } from "../service/helper"
import UseCustomValidator from "../service/simpleValidator"

const initialFormState = {
    webform_id: "refer_a_patient",
    patient_first_name: "",
    patient_last_name: "",
    patient_country: "",
    patient_state: "",
    patient_city: "",
    age: "",
    gender: "male",
    patient_diagnosis: "",
    date_of_diagnosis: null,
    treatment_information: "",
    surgery_information: "",
    additional_information: "",
    parent_gardian_mobile: "",
    referral_information_for_physician: "",
    referring_physician_name: "",
    referring_physician_email: "",
    referring_physician_country: "",
    referring_physician_mobile: "",
    reffered_by_i_am: "Patient",
    reffered_by_my_email: ""
}


const ReferPatient = ({ data: { allNodeTemplatePages, allCountriesJson : { edges } } }) => {
    const rawData = allNodeTemplatePages?.edges[0]
    const metaTag = [];
    const data = rawData?.node?.relationships?.components;
    const bannerData = data[0];
    // bannerSection
    const bannerImg = typeof (bannerData?.relationships?.components[0]?.relationships?.image) !== "undefined" ? bannerData?.relationships?.components[0]?.relationships?.image?.uri?.url : ""
    const mobileBannerImg = bannerData?.relationships?.components?.[0]?.relationships?.mobileBannerImage?.uri?.url || bannerImg;
    const bannerAlt = typeof (bannerData?.relationships?.components[0]?.image?.alt) !== "undefined" && bannerData?.relationships?.components[0]?.image?.alt.length > 0 ? bannerData?.relationships?.components[0]?.image?.alt : "Refer a patient"
    
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [disableStates, setDisableStates] = useState(true);
    const [disableCities, setDisableCities] = useState(true);
    const dateRef = useRef()
    const [thankYou, setThankYou] = useState(false)
    const [btnloader, setBtnLoader] = useState(false)
    useEffect(() => {
        const data = edges?.reduce((acc, country) => {
            if (country.node.states.length > 0) {
                acc = [...acc, country.node.name];
            }
            return acc;
        }, []);

        setCountries(data);
    }, [])


    useEffect(() => {
        let forDate = document.getElementById("forDate");
        if (forDate !== "undefined" && forDate !== null) {
            forDate.addEventListener("focusin", () => {
                document.getElementById("date-diagnosis").classList.add("-focus")
            })

            forDate.addEventListener("focusout", () => {
                document.getElementById("date-diagnosis").classList.remove("-focus")

            })
        }

        return () => {
            if (forDate !== "undefined" && forDate !== null) {
                forDate.removeEventListener("focusin", () => {
                    document.getElementById("date-diagnosis").classList.add("-focus")
                })
                forDate.removeEventListener("focusout", () => {
                    document.getElementById("date-diagnosis").classList.remove("-focus")

                })
            }
        }
    })

    useScript("/assets/js/custom_select.js")

    const [, forceUpdate] = useState()
    const { customMobileValidator } = UseCustomValidator()
    const formValidator = customMobileValidator

    let [formData, setFormData] = useState(initialFormState)



    const onFormDataChange = (event) => {
        if (event.target.name === "parent_gardian_mobile" || event.target.name === 'referring_physician_mobile' ||  event.target.name === 'age') {
            let val = acceptOnlyNumbers(event.target.value)
            setFormData({
                ...formData,
                [event.target.name]: val
            })
        } else {
            setFormData({
                ...formData,
                [event.target.name]: event.target.value
            })
        }
        if (event.target.name === "patient_country") {
            setFormData({
                ...formData,
                [event.target.name]: event.target.value,
                "patient_state": "",
                "patient_city": ""
            })
            const [data] = edges?.filter((item) => item.node.name === event.target.value);
            const { node: { states } } = data;
            setStates(states)
            setDisableStates(false);

        }
        if (event.target.name === "patient_state" && formData.patient_country.length > 0) {
            setFormData({
                ...formData,
                [event.target.name]: event.target.value,
                "patient_city": ""
            })
            const [data] = states.filter(el => el.name === event.target.value)
            const { cities } = data
            if (cities.length > 0) {
                setCities(cities.map(city => city.name));
                setDisableCities(false);
            } else {
                window.alert("No cities available, please choose a different state");
            }
        }
    }

    useEffect(() => {
        if (typeof window.initSelect !== 'undefined') window.initSelect();
    }, [cities, states, thankYou])



    const onSubmit = () => {
        const result = formValidator.current.allValid()
        if (result === false) {
            formValidator.current.showMessages()
            forceUpdate(true)
        } else {
            setBtnLoader(true)
            api.submitPatientDetails(formData).then(res => {
                if (res.data) {
                    setThankYou(true)
                    setFormData(initialFormState)
                    window.scroll({ top: 0, behavior: "smooth" })
                }
                setBtnLoader(false)
            }).catch(err => {
                toast.error("Something went wrong")
                setFormData(initialFormState)
                setBtnLoader(false)
            })
        }
    }

    const resetThankyou = (value) => {
        if(window) window.location.reload();
    }

    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: ["/assets/js/form-inputs-anim.js"],
                        css: ["/assets/css/login.css", "/assets/css/accessibility.css"],
                    }}
                    tags={metaTag}
                />
                <main className="innerpage">
                    {
                        thankYou ? <Thankyou setThankYou={resetThankyou} /> : (
                            <>
                                <section className="inner_common_banner">
                                    <picture className="banner_img">
                                        <img src={mobileBannerImg} alt={bannerAlt} className="d-md-none"/>
                                        <img src={bannerImg} alt={bannerAlt} className="d-md-block d-none"/>
                                    </picture>
                                    <div className="banner_content text_left text_dark">
                                        <div className="container">
                                            <h1>Refer A Patient</h1>
                                        </div>
                                    </div>
                                </section>
                                <section className="section_bg login_section pt-first-section">
                                    <div className="container">
                                        <div className="row no-gutters">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="white_rounded_box">
                                                    <div className="form_wrap" id="register_form_wrap">
                                                        <div className="form_block pl-0">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group -animated">
                                                                        <label>Patient Firstname <span className="mandatory">*</span></label>
                                                                        <input className="form-control" type="text" onChange={onFormDataChange} value={formData.patient_first_name} name="patient_first_name" />
                                                                        {formValidator.current.message('Firstname', formData.patient_first_name, 'required|alpha')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group -animated">
                                                                        <label>Patient Lastname <span className="mandatory">*</span></label>
                                                                        <input className="form-control" type="text" onChange={onFormDataChange} name="patient_last_name" value={formData.patient_last_name} />
                                                                        {formValidator.current.message('Lastname', formData.patient_last_name, 'required|alpha')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group select-dd">
                                                                        <label>Country</label>
                                                                        <select name="patient_country" className="form-control custom-select" placeholder={formData.patient_country ? formData.patient_country : "Select Country"} onChange={onFormDataChange}>
                                                                            {
                                                                                countries.map((item, index) => {
                                                                                    return (
                                                                                        <option value={item} key={index}>{item}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    {formValidator.current.message('Patient Country', formData.patient_country, 'required')}
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className={`form-group select-dd ${disableStates ? 'select-disabled' : ''}`}>
                                                                        <label>Select State</label>
                                                                        <select name="patient_state" className="form-control custom-select" placeholder={formData.patient_state ? formData.patient_state : "Select State"} onChange={onFormDataChange}>
                                                                            {
                                                                                states.map((item, index) => {
                                                                                    return (
                                                                                        <option value={item.name} key={index}>{item.name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    {formValidator.current.message('Patient State', formData.patient_state, 'required')}
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className={`form-group select-dd ${disableCities ? 'select-disabled' : ''}`}>
                                                                        <label>City</label>
                                                                        <select name="patient_city" className="form-control custom-select" placeholder={formData.patient_city ? formData.patient_city : "Select City"} onChange={onFormDataChange}>

                                                                            {
                                                                                cities.map((item, index) => {
                                                                                    return (
                                                                                        <option value={item} key={index}>{item}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    {cities.length > 0 ? formValidator.current.message('Patient City', formData.patient_city, 'required') : null}
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group -animated">
                                                                        <label>Age <span className="mandatory">*</span></label>
                                                                        <input className="form-control" type="text" onChange={onFormDataChange} name="age" value={formData.age} />
                                                                        {formValidator.current.message('Age', formData.age, 'required|min:1|max:2')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group select-dd">
                                                                        <label>Select Gender</label>
                                                                        <select name="gender" className="form-control custom-select" placeholder="Select Gender" onChange={onFormDataChange} value={formData.gender}>
                                                                            <option value="male">Male</option>
                                                                            <option value="female">Female</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="form-group -animated">
                                                                        <label>Patient Diagnosis</label>
                                                                        <textarea className="form-control" name="patient_diagnosis" onChange={onFormDataChange} value={formData.patient_diagnosis}></textarea>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className={`form-group -animated ${formData.date_of_diagnosis !== null ? "-active" : ""}`} id="date-diagnosis">
                                                                        <label>Date of Diagnosis <span className="mandatory">*</span></label>
                                                                        <DatePicker selected={formData.date_of_diagnosis} onChange={(date) => setFormData({ ...formData, date_of_diagnosis: date })} name="date_of_diagnosis" className="form-control" id="forDate" ref={dateRef} />
                                                                        {formValidator.current.message('DiagnosisDate', formData.date_of_diagnosis, 'required')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="form-group -animated">
                                                                        <label>Any Treatment?</label>
                                                                        <textarea className="form-control" name="treatment_information" onChange={onFormDataChange} value={formData.treatment_information}></textarea>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="form-group -animated">
                                                                        <label>Any Surgery?</label>
                                                                        <textarea className="form-control" name="surgery_information" onChange={onFormDataChange} value={formData.surgery_information}></textarea>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="form-group -animated">
                                                                        <label>Additional Information</label>
                                                                        <textarea className="form-control" name="additional_information" onChange={onFormDataChange} value={formData.additional_information}></textarea>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group -animated">
                                                                        <label>Parent/Guardian Mobile <span className="mandatory">*</span></label>
                                                                        <input className="form-control" type="tel" onChange={onFormDataChange} name="parent_gardian_mobile" value={formData.parent_gardian_mobile} />
                                                                        {formValidator.current.message('Mobile Number', formData.parent_gardian_mobile, 'required|min:10|max:10|validateMobile')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="form-group -animated">
                                                                        <label>Referral Information for Physician</label>
                                                                        <textarea className="form-control" name="referral_information_for_physician" onChange={onFormDataChange} value={formData.referral_information_for_physician}></textarea>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group -animated">
                                                                        <label>Referring Physician Name <span className="mandatory">*</span></label>
                                                                        <input className="form-control" type="text" onChange={onFormDataChange} name="referring_physician_name" value={formData.referring_physician_name} />
                                                                        {formValidator.current.message('Referring Doctor Name', formData.referring_physician_name, 'required')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group -animated">
                                                                        <label>Referring Physician Email <span className="mandatory">*</span></label>
                                                                        <input className="form-control" type="email" onChange={onFormDataChange} name="referring_physician_email" value={formData.referring_physician_email} />
                                                                        {formValidator.current.message('Referring Doctor email', formData.referring_physician_email, 'required|email')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group select-dd">
                                                                        <label>Select Referring Physician Country <span className="mandatory">*</span></label>
                                                                        <select className="form-control custom-select" placeholder="Select Country" name="referring_physician_country" value={formData.referring_physician_country} onChange={onFormDataChange}>
                                                                            {
                                                                                countries.map((item, index) => {
                                                                                    return (
                                                                                        <option value={item} key={index}>{item}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                        {formValidator.current.message('Referring Physician Country', formData.referring_physician_country, 'required')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group -animated">
                                                                        <label>Referring Physician Mobile <span className="mandatory">*</span></label>
                                                                        <input className="form-control" type="text" onChange={onFormDataChange} name="referring_physician_mobile" value={formData.referring_physician_mobile} />
                                                                        {formValidator.current.message('Referring Physician Mobile', formData.referring_physician_mobile, 'required|phone')}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group select-dd">
                                                                        <label>I am</label>
                                                                        <select name="reffered_by_i_am" value={formData.reffered_by_i_am} className="form-control custom-select" placeholder="Select Option" onChange={onFormDataChange}>
                                                                            <option value="Patient">Patient</option>
                                                                            <option value="Physician">Physician</option>
                                                                            <option value="Family">Family</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group -animated">
                                                                        <label>My Email <span className="mandatory">*</span></label>
                                                                        <input className="form-control" type="email" onChange={onFormDataChange} name="reffered_by_my_email" value={formData.reffered_by_my_email} />
                                                                        {formValidator.current.message('email', formData.reffered_by_my_email, 'required|email')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4">
                                                                <APIButton 
                                                                    title={`Submit Details`}
                                                                    loading={btnloader}
                                                                    disabled={btnloader}
                                                                    onBtnClick={() => onSubmit()}  
                                                                    className={`btn btn-primary`} 
                                                                />
                                                                {/* <button className="btn btn-primary" onClick={onSubmit}>Submit Details</button> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </>
                        )
                    }

                </main>
            </Layout>
        </>
    )
}

export const query = graphql`
query referAPatientAndMyQuery {
    allNodeTemplatePages(filter: {path: {alias: {regex: "/refer-a-patient$/"}}}) {
      edges {
        node {
          id
          title
          path {
            alias
          }
          metatag {
            attributes {
                content
                href
                name
                property
                rel
            }
          }
          relationships {
            components: field_component_type {
              __typename
              ...ParagraphSectionRAP
            }
          }
        }
      }
    }
  allCountriesJson {
      edges {
        node {
          name
          states {
            name
            cities {
              name
            }
          }
        }
      }
    }
  }
  
  fragment ParagraphSectionRAP on paragraph__section {
    id
    name: field_name
    relationships {
      components: field_components {
        __typename
        ...ParagraphBannerRAP
      }
    }
  }
  
  fragment ParagraphBannerRAP on paragraph__banner {
    id
    title: field_title
    subtitle: field_subtitle
    image: field_banner_image {
      alt
    }
    mobileBannerImage: field_mobile_banner {
      alt
    }
    bannerLink: field_banner_link {
      uri
      title
    }
    relationships {
      image: field_banner_image {
        id
        uri {
          value
          url
        }
      }
      mobileBannerImage: field_mobile_banner {
        id
        uri {
          url
          value
        }
      }
    }
  }  
`
export default ReferPatient